import { Box } from '@achieve/sunbeam'
import { DatSection } from '@achieve/cx-debt-assessment'
import Section from 'components/Section/Section'
import styles from './DebtAssessmentSection.module.scss'
import { TrackingWrapper } from 'components/Tracking/TrackingWrapper'
import { useRef, useEffect, useContext, useState } from 'react'
import { AchieveTheme as theme } from '@achieve/sunbeam'

import { AnalyticsContext } from 'providers/AnalyticsProvider'
import { useMemoizedContentGetter } from 'utils/contentful'
import { TitleDivider } from 'components/TitleDivider'

function DebtAssessmentSection({ content }) {
  const trackRefCta = useRef()
  const trackRefLink = useRef()
  const trackRefSelect = useRef()
  const { dispatch } = useContext(AnalyticsContext)
  const { eyebrow, title } = useMemoizedContentGetter(content, ['eyebrow', 'title'])

  const additionalConfiguration =
    content?.fields?.additionalConfiguration?.fields?.jsonContent || {}

  const [formSelectedValue, setFormSelectedValue] = useState(0)

  const args = {
    ...additionalConfiguration,
    onCtaClick: function () {
      trackRefCta?.current?.click()
    },
    onClick: function () {
      trackRefLink?.current?.click()
    },
    fireEvent: function (value) {
      setFormSelectedValue(value)
    },
  }

  const backgroundColorMap = {
    white: theme.sb?.colors?.neutral?.white,
    lightGray: theme.sb?.colors?.neutral?.grey?.[8],
    lightBlue: theme.sb?.colors?.primary?.highlightBlue,
  }

  // useEffect to fire initial api call event and any changes from the select field
  useEffect(() => {
    if (formSelectedValue) {
      trackRefSelect?.current?.click()
    } else {
      dispatch({
        type: 'ADD_EVENT_TO_QUEUE',
        payload: {
          event_action: 'app_event',
          form_ss_amount: `${
            additionalConfiguration?.defaultSelectedValue ??
            additionalConfiguration?.selectItemList?.[0]?.value
          }`,
          track_event: 'tool_render',
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formSelectedValue])

  return (
    <Section
      backgroundColor={
        backgroundColorMap[additionalConfiguration?.backgroundColor] ?? backgroundColorMap.white
      }
      className={styles['container']}
    >
      <Box className={styles['box-form']}>
        <TitleDivider eyebrowBlack={true} eyebrow={eyebrow} title={title} />
        <TrackingWrapper
          data-testid="dat-wait-link-click"
          track={{
            list_name: 'DAT WAIT LINK',
            click_id: additionalConfiguration?.datWaitProps?.linkText,
            click_text: `Achieve-Web | ${additionalConfiguration?.datWaitProps?.linkText}`,
            click_url: additionalConfiguration?.datWaitProps?.linkHref,
          }}
        >
          <div ref={trackRefLink}></div>
        </TrackingWrapper>
        <TrackingWrapper
          data-testid="dat-button-click"
          track={{
            list_name: 'DAT BUTTON',
            click_id: additionalConfiguration?.CtaCopy,
            click_text: `Achieve-Web | ${additionalConfiguration?.CtaCopy}`,
            click_url: additionalConfiguration?.CtaUrl,
          }}
        >
          <div ref={trackRefCta}></div>
        </TrackingWrapper>
        <TrackingWrapper
          data-testid="dat-select-field"
          track={{
            list_name: 'DAT SELECT FIELD',
            click_id: formSelectedValue,
            click_text: `Achieve-Web | ${formSelectedValue}`,
            form_ss_amount: formSelectedValue,
          }}
        >
          <div ref={trackRefSelect}></div>
        </TrackingWrapper>
        <DatSection {...args} />
      </Box>
    </Section>
  )
}
export { DebtAssessmentSection }
export default DebtAssessmentSection
